import React from 'react'
import Heading from 'uiKit/texts/Heading'
import Paragraph from 'uiKit/texts/Paragraph'
import Switch from 'uiKit/Switch'

import { toggleLivechatEnabler } from '../../../api/settings'

import * as S from './LivechatEnablerBlock.styles'

export const LivechatEnablerBlock = ({ activeBot }) => {
  const handleToggleLivechatEnabler = e => {
    toggleLivechatEnabler(activeBot.id, e.target.checked)
  }

  return (
    <S.LivechatEnablerBlock>
      <Heading>Enable/Disable Live chat</Heading>
      <Paragraph>Once disabled, the bot will not redirect users to the live support.</Paragraph>
      <Switch
        useSecondaryColor={true}
        label="Enable live chat"
        checked={activeBot?.livechatEnabled}
        onChange={handleToggleLivechatEnabler}
      />
    </S.LivechatEnablerBlock>
  )
}
